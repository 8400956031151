import { FC } from "react";

import { useTranslations } from "next-intl";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

import { CurrencyType, ProductViewType } from "types/QuickCartTypes";

import ProductBoxMainPage from "components/ProductBoxMainPage";

import { colorPrimary700 } from "theme/colors";

type RecommendedProductTabProps = {
  page: {
    currency: CurrencyType;
    language?: string;
    siteId?: string;
  };
  recommendedProducts?: ProductViewType[];
};

const RecommendedProductTab: FC<RecommendedProductTabProps> = ({
  page,
  recommendedProducts,
}) => {
  const t = useTranslations("common");
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={5}
    >
      {recommendedProducts?.map((productItem, i) => {
        return (
          <Grid
            key={productItem.id}
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            xl={4}
            sx={{
              display: i < 3 ? "block" : { xs: "none", sm: "block" },
            }}
          >
            <ProductBoxMainPage dataBox={productItem} data={page} />
          </Grid>
        );
      })}
      <Grid item xs={12}>
        <Box my={5} className={"p-align-center"}>
          <Link href="/badania" sx={{ color: colorPrimary700 }} variant="h6">
            {t("index-category-more-products")}
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};
export default RecommendedProductTab;
