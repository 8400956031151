import { FC } from "react";

import { any, node } from "prop-types";

import Box from "@mui/material/Box";

interface TabPanel {
  name?: string;
  children: any;
  index: string | number;
  value: any;
}

// TABS: START
export const TabPanel: FC<TabPanel> = (props): JSX.Element => {
  const { name, children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      style={{ display: value === index ? "block" : "none" }}
      id={`${name}-scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`${name}-scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box my={10}>{children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  name: any.isRequired,
  children: node,
  index: any.isRequired,
  value: any.isRequired,
};

export function a11yProps(
  name: string,
  index: number,
): { id: string; "aria-controls": string } {
  return {
    id: `${name}-scrollable-auto-tab-${index}`,
    "aria-controls": `${name}-scrollable-auto-tabpanel-${index}`,
  };
}
