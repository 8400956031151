"use client";
import { FC, useState } from "react";

import { useTranslations } from "next-intl";
import { SwiperSlide } from "swiper/react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

import ArticleMainBox from "components/ArticleMainBox";
import { a11yProps, TabPanel } from "components/TabPanel/TabPanel";
import MainArticleSwiper from "components/MainArticleSwiper/MainArticleSwiper";

import { cleanUpImageUrl } from "utils/ImagesUrl";

import {
  colorOrange,
  colorPrimary700,
  colorText100,
  colorText900,
} from "theme/colors";

interface MainBlogTabProps {
  articlesPopular: any[];
  commonBlogPage: string;
  articlesNew: any[]; // Define the props for the component here
}

const MainBlogTab: FC<MainBlogTabProps> = ({
  articlesPopular,
  commonBlogPage,
  articlesNew,
}): JSX.Element => {
  const t = useTranslations("common");
  const [valueTabBlog, setValueTabBlog] = useState(0);
  const handleChangeTabBlog = (event, newValue) => {
    setValueTabBlog(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent={{ xs: "center", sm: "space-between" }}
        alignItems={{ xs: "start", sm: "flex-end" }}
        spacing={2}
      >
        <Box>
          <Typography variant="subtitle2" sx={{ color: colorOrange, mb: 2 }}>
            {t("index-articles-text")}
          </Typography>
          <Typography
            variant="h2"
            component="h2"
            fontWeight={700}
            sx={{ color: colorText900 }}
          >
            {t("index-articles-title")}
          </Typography>
        </Box>
        <Box>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            sx={{ background: "transparent" }}
          >
            <Box className="fcx-tabs-stacked-box">
              <Tabs
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                value={valueTabBlog}
                onChange={handleChangeTabBlog}
                indicatorColor="primary"
                textColor="primary"
                className={"fcx-tabs-stacked"}
              >
                <Tab
                  label={t("index-articles-new")}
                  {...a11yProps("articles", 0)}
                  sx={{
                    color: colorText900,
                    "&.Mui-selected": {
                      background: colorText900,
                      color: colorText100,
                      borderRadius: "2rem",
                    },
                  }}
                />
                <Tab
                  label={t("index-articles-popular")}
                  {...a11yProps("articles", 1)}
                  sx={{
                    color: colorText900,
                    "&.Mui-selected": {
                      background: colorText900,
                      color: colorText100,
                      borderRadius: "2rem",
                    },
                  }}
                />
              </Tabs>
            </Box>
          </AppBar>
        </Box>
      </Stack>

      <TabPanel value={valueTabBlog} index={0} key={0} name="articles">
        <MainArticleSwiper>
          {articlesNew.map((a, i) => {
            return (
              <SwiperSlide key={`article-${i}`}>
                <ArticleMainBox
                  title={a.title}
                  image={`${
                    process.env.NEXT_PUBLIC_ASSETS_URL
                  }${cleanUpImageUrl(a.image)}`}
                  url={a.url}
                  categoryName={a.categoryName}
                  categoryHref={a.categoryHref}
                  date={a.date}
                />
              </SwiperSlide>
            );
          })}
        </MainArticleSwiper>
      </TabPanel>
      <TabPanel value={valueTabBlog} index={1} key={1} name="articles">
        <MainArticleSwiper>
          {articlesPopular.map((a, i) => {
            return (
              <SwiperSlide key={`popular-${i}`}>
                <ArticleMainBox
                  title={a.title}
                  image={`${
                    process.env.NEXT_PUBLIC_ASSETS_URL
                  }${cleanUpImageUrl(a.image)}`}
                  url={a.url}
                  categoryName={a.categoryName}
                  categoryHref={a.categoryHref}
                  date={a.date}
                />
              </SwiperSlide>
            );
          })}
        </MainArticleSwiper>
      </TabPanel>
      <Box my={6} className={"p-align-center"}>
        <Link
          href={commonBlogPage}
          sx={{ color: colorPrimary700 }}
          variant="h6"
        >
          {t("index-articles-all")}
        </Link>
      </Box>
    </Container>
  );
};
export default MainBlogTab;
