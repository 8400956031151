"use client";
import React, { useState } from "react";

import { useTranslations } from "next-intl";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { ProductViewType } from "types/QuickCartTypes";

import ProductBoxMainPage from "components/ProductBoxMainPage";
import RecommendedProductTab from "components/StaticRecommendedProductTab/RecommendedProductTab";
import { TabPanel, a11yProps } from "components/TabPanel/TabPanel";

import {
  colorPrimary700,
  colorText100,
  colorText800,
  colorText900,
} from "theme/colors";

interface MainProductsTabProps {
  products: any[];
  recommendedProducts?: ProductViewType[];
  commonProductsPage: string;
  page: any; // Define the props for the component here
}

const MainProductsTab: React.FC<MainProductsTabProps> = (props) => {
  const { products, commonProductsPage, page, recommendedProducts } = props;
  const t = useTranslations("common");
  // Implement the component logic here
  const [valueTab, setValueTab] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Typography
        variant="h2"
        component="h2"
        gutterBottom={true}
        align={"center"}
        fontWeight={700}
        sx={{ color: colorText800, mb: 10 }}
      >
        {t("main-page-catalog")}
      </Typography>
      <Box mb={5}>
        <AppBar
          position="static"
          color="default"
          elevation={0}
          style={{ background: "transparent" }}
        >
          <Box className="fcx-tabs-stacked-box">
            <Tabs
              value={valueTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={true}
              className={"fcx-tabs-stacked"}
              allowScrollButtonsMobile
            >
              <Tab
                component={"a"}
                label={t("main-page-recommended-tests")}
                sx={{
                  color: colorText900,
                  padding: "0 30px",
                  maxWidth: "unset",
                  "&.Mui-selected": {
                    background: colorText900,
                    color: colorText100,
                    borderRadius: "2rem",
                  },
                }}
              />
              {products.map((p, i) => {
                return (
                  <Tab
                    key={`tab-box-${i}`}
                    label={
                      p.shortName && p.shortName !== "" ? p.shortName : p.name
                    }
                    {...a11yProps("products", i)}
                    sx={{
                      color: colorText900,
                      padding: "0 30px",
                      maxWidth: "unset",
                      "&.Mui-selected": {
                        background: colorText900,
                        color: colorText100,
                        borderRadius: "2rem",
                      },
                    }}
                  />
                );
              })}
              <Tab
                component={"a"}
                href={commonProductsPage}
                label={t("index-category-product-tabs")}
                {...a11yProps("products", products.length)}
                sx={{
                  color: colorText900,
                  padding: "0 30px",
                  maxWidth: "unset",
                  "&.Mui-selected": {
                    background: colorText900,
                    color: colorText100,
                    borderRadius: "2rem",
                  },
                }}
              />
            </Tabs>
          </Box>
        </AppBar>
      </Box>
      <TabPanel value={valueTab} index={0} name="products">
        <RecommendedProductTab
          page={page}
          recommendedProducts={recommendedProducts}
        />
      </TabPanel>
      {products.map((p, i) => {
        return (
          <TabPanel
            key={`products-${i}`}
            value={valueTab}
            index={i + 1}
            name="products"
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              spacing={5}
            >
              {p.products.map((productItem, ii) => {
                return (
                  <Grid
                    key={`subproduct-${ii}`}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: ii < 3 ? "block" : { xs: "none", sm: "block" },
                    }}
                  >
                    <ProductBoxMainPage dataBox={productItem} data={page} />
                  </Grid>
                );
              })}
              <Grid item xs={12}>
                <Box my={5} className={"p-align-center"}>
                  <Link
                    href={p.url}
                    sx={{ color: colorPrimary700 }}
                    variant="h6"
                    fontWeight={700}
                  >
                    {t("index-category-more-products")}
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
        );
      })}
    </Container>
  );
};

export default MainProductsTab;
