import { FC, memo } from "react";

import { useSwiper } from "swiper/react";

import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { SxProps } from "@mui/material";

import { colorText500, colorText800 } from "theme/colors";

type NavigationPropsType = {
  sx: SxProps;
};

const Navigation: FC<NavigationPropsType> = ({ sx }) => {
  const swiper = useSwiper();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={sx}
    >
      <IconButton
        onClick={() => {
          swiper.slidePrev();
        }}
        aria-label="Poprzednie"
      >
        <WestIcon sx={{ fontSize: "2rem", color: colorText500 }} />
      </IconButton>
      <FiberManualRecordIcon sx={{ fontSize: "0.8rem", color: colorText800 }} />
      <IconButton
        onClick={() => {
          swiper.slideNext();
        }}
        aria-label="Następne"
      >
        <EastIcon
          fontSize={"large"}
          sx={{ fontSize: "2rem", color: colorText500 }}
        />
      </IconButton>
    </Stack>
  );
};
export default memo(Navigation);
