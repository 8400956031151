import { FC, useState } from "react";

import Image from "next/image";

import { useTranslations } from "next-intl";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Stack from "@mui/material/Stack";

import { CurrencyType } from "types/QuickCartTypes";

import SlideNavigation from "components/MainCarousel/SlideNavigation";

import { cleanUpImageUrl } from "utils/ImagesUrl";

import { colorOrange, colorText50, colorText800 } from "theme/colors";
import { ScissorsIcon } from "theme/SvgIcons";

type SlidePromotionProps = {
  buttonUrl: string;
  image: string;
  imageMobile: string;
  imageAlt?: string;
  header: string;
  button: string;
  itemNumber: number;
  elementsCount: number;
  code: string;
  validateTo: string;
  priceFrom: string;
  currency: CurrencyType;
};

const SlidePromotion: FC<SlidePromotionProps> = ({
  buttonUrl,
  button,
  image,
  imageMobile,
  imageAlt,
  header,
  itemNumber,
  elementsCount,
  priceFrom,
  validateTo,
  code,
  currency,
}) => {
  const t = useTranslations("common");
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(code).then(() => setIsCopied(true));
  };

  return (
    <Box
      onClick={() =>
        buttonUrl
          ? (document.location.href = buttonUrl)
          : console.error("no-slide-url")
      }
    >
      <Box
        height="610px"
        style={{
          cursor: "pointer",
        }}
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Image
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}${cleanUpImageUrl(
              image,
            )}`}
            fill
            priority={itemNumber === 1}
            alt={imageAlt ? imageAlt : ""}
            // unoptimized
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Image
            src={`${process.env.NEXT_PUBLIC_ASSETS_URL}${cleanUpImageUrl(
              imageMobile && imageMobile !== "" ? imageMobile : image,
            )}`}
            fill
            priority={itemNumber === 1}
            alt={imageAlt ? imageAlt : ""}
            // unoptimized
            style={{ objectFit: "cover" }}
          />
        </Box>
        <Container
          maxWidth="xl"
          className={"fullHeight"}
          sx={{ position: "relative" }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            textAlign={"left"}
            px={"40px"}
            pt={"40px"}
            pb={"80px"}
            className={"fullHeight"}
          >
            {header && (
              <>
                {/* *** DESKTOP view start *** */}
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Box sx={{ width: { md: "70%", lg: "60%", xl: "50%" } }}>
                      {/* DESKTOP CODE VALID TO START  */}
                      {validateTo && validateTo !== "" && (
                        <Typography
                          variant="h6"
                          component="div"
                          color={colorOrange}
                          sx={{ mb: 5 }}
                          fontWeight={600}
                        >
                          {`${t(
                            "main-page-slider-code-valid-to",
                          )} ${validateTo}`}
                        </Typography>
                      )}
                      {/*  DESKTOP CODE VALID TO END  */}
                      {/* DESKTOP MAIN TEXT START  */}
                      <Typography
                        variant="h1"
                        component="h2"
                        color={colorText50}
                        sx={{
                          fontSize: "4.3rem",
                          fontWeight: 700,
                          lineHeight: 1.1,
                          mb: 5,
                        }}
                      >
                        {header}
                        {code && code !== "" && (
                          <Tooltip
                            title={
                              isCopied
                                ? t("main-page-slider-code-copied")
                                : t("main-page-slider-code-to-copy")
                            }
                          >
                            <Box
                              onClick={copyToClipboard}
                              sx={{
                                display: "inline-block",
                                alignItems: "center",
                                alignContent: "center",
                                borderBottomStyle: "dashed",
                                borderWidth: "3px",
                                borderColor: colorOrange,
                                color: colorOrange,
                              }}
                            >
                              <Box component={"span"} sx={{ ml: 4, mr: 2 }}>
                                {code}
                              </Box>
                              <Box sx={{ display: "inline-block" }}>
                                <ScissorsIcon />
                              </Box>
                            </Box>
                          </Tooltip>
                        )}
                      </Typography>
                    </Box>
                    {/* DESKTOP MAIN TEXT END */}
                    {/*  DESKTOP ROUND BOX PRICE START*/}
                    {priceFrom && priceFrom !== "" && (
                      <Stack
                        sx={{
                          direction: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "absolute",
                          top: { xs: "unset", md: "10%" },
                          bottom: { xs: "18%", md: "unset" },
                          right: { xs: "-1%", md: "10%" },
                          background: colorOrange,
                          borderRadius: "100px",
                          width: "180px",
                          height: "180px",
                          zIndex: 1000000,
                        }}
                      >
                        <Typography
                          variant="h5"
                          component="div"
                          color={colorText50}
                          fontWeight={400}
                        >
                          {t("main-page-slider-promotion-price-from")}
                        </Typography>
                        <Typography
                          variant="h3"
                          component="div"
                          color={colorText50}
                          fontWeight={400}
                          sx={{ fontWeight: "2rem" }}
                        >
                          {priceFrom}&nbsp;{currency.code}
                        </Typography>
                      </Stack>
                    )}
                    {/* DESKTOP ROUND BOX PRICE END  */}
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent={
                      button && buttonUrl ? "space-between" : "flex-end"
                    }
                    alignItems="flex-end"
                  >
                    {button && buttonUrl && (
                      <Button
                        endIcon={<ArrowOutwardIcon />}
                        variant="contained"
                        color="secondary"
                        disableElevation={true}
                        className={"fcx-pt-5 fcx-pb-10 fcx-px-40 buttonSlider"}
                        href={buttonUrl}
                      >
                        {button}
                      </Button>
                    )}
                    <SlideNavigation
                      itemNumber={itemNumber}
                      elementsCount={elementsCount}
                    />
                  </Stack>
                </Box>
                {/* *** DESKTOP view end *** */}

                {/* *** MOBILE view start *** */}
                <Box
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {/*  MOBILE ROUND BOX PRICE START  */}
                  {priceFrom && priceFrom !== "" && (
                    <Stack
                      sx={{
                        direction: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: { xs: "unset", md: "10%" },
                        bottom: { xs: "16%", md: "unset" },
                        right: { xs: "-1%", md: "10%" },
                        background: colorOrange,
                        borderRadius: "100px",
                        width: "150px",
                        height: "150px",
                        zIndex: 1000000,
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="div"
                        color={colorText50}
                        fontWeight={400}
                      >
                        {t("main-page-slider-promotion-price-from")}
                      </Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        color={colorText50}
                        fontWeight={400}
                        sx={{ fontWeight: "1.5rem" }}
                      >
                        {priceFrom}&nbsp;{currency.code}
                      </Typography>
                    </Stack>
                  )}
                  {/*  MOBILE ROUND BOX PRICE END*/}
                  {/*  MOBILe CODE VALID TO START*/}
                  {validateTo && validateTo !== "" && (
                    <Box mb={7}>
                      <Typography
                        variant="subtitle2"
                        component="span"
                        color={colorOrange}
                        sx={{
                          background: colorText800,
                          borderRadius: "100px",
                          p: "10px 10px 10px 10px",
                          lineHeight: 1,
                        }}
                      >
                        {`${t("main-page-slider-code-valid-to")} ${validateTo}`}
                      </Typography>
                    </Box>
                  )}
                  {/* MOBILe CODE VALID TO END  */}
                  <Typography
                    variant="h1"
                    component="h2"
                    className={"fcx-mb-40"}
                    fontSize={"2.6rem"}
                    fontWeight={700}
                    color={colorText50}
                  >
                    {header}
                    {code && code !== "" && (
                      <Tooltip
                        title={
                          isCopied
                            ? t("main-page-slider-code-copied")
                            : t("main-page-slider-code-to-copy")
                        }
                      >
                        <Box
                          onClick={copyToClipboard}
                          sx={{
                            display: "inline-block",
                            alignItems: "center",
                            alignContent: "center",
                            borderBottomStyle: "dashed",
                            borderWidth: "3px",
                            borderColor: colorOrange,
                            color: colorOrange,
                          }}
                        >
                          <Box component={"span"} sx={{ ml: 4 }}>
                            {code}
                          </Box>{" "}
                          <Box sx={{ display: "inline-block" }}>
                            <ScissorsIcon />
                          </Box>
                        </Box>
                      </Tooltip>
                    )}
                  </Typography>
                  {button && buttonUrl && (
                    <Button
                      endIcon={<ArrowOutwardIcon />}
                      variant="contained"
                      color="secondary"
                      disableElevation={true}
                      className={
                        "fcx-pt-5 fcx-pb-10 fcx-px-20 buttonSliderMobile"
                      }
                      href={buttonUrl}
                    >
                      {button}
                    </Button>
                  )}
                </Box>
                {/* MOBILE NAVIGATION START */}
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "2rem",
                    alignContent: "center",
                    width: "100%",
                    marginLeft: "-40px",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <SlideNavigation
                    itemNumber={itemNumber}
                    elementsCount={elementsCount}
                  />
                </Box>
                {/* MOBILE NAVIGATION START */}
                {/* *** MOBILE view end *** */}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
export default SlidePromotion;
