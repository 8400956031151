"use client";
import { FC, useState } from "react";

import { useTranslations } from "next-intl";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";

import HtmlText from "components/HtmlText";

import {
  colorPrimary200,
  colorPrimary700,
  colorText800,
  colorText900,
} from "theme/colors";

interface MainFaqBoxProps {
  faq: any;
  sx: SxProps;
}

const MainFaqBox: FC<MainFaqBoxProps> = ({ faq, sx }) => {
  const t = useTranslations("common");

  const [expanded, setExpanded] = useState();
  const handleChangeExpansionPanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box sx={sx}>
      <Typography
        variant="h4"
        component="h3"
        gutterBottom={true}
        className={"fcx-mb-30 fcx-mt-20"}
        fontWeight={700}
        color={colorText900}
      >
        {t("main-page-faq-title")}
      </Typography>
      {faq?.qa !== null &&
        faq?.qa.map((e, i) => {
          return (
            <Accordion
              key={`faqu-item-${i}`}
              className={"b-transparent"}
              elevation={0}
              expanded={expanded === "panel" + i}
              onChange={handleChangeExpansionPanel("panel" + i)}
              sx={{
                borderTop: i === 0 ? `1px solid ${colorPrimary200}` : "none",
                borderBottom: `1px solid ${colorPrimary200}`,
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={"panel" + i + "bh-content"}
                id={"panel" + i + "bh-header"}
              >
                <HtmlText
                  variant="subtitle1"
                  component="h3"
                  sx={{ color: colorPrimary700 }}
                >
                  {e.question}
                </HtmlText>
              </AccordionSummary>
              <AccordionDetails>
                <HtmlText component="div" sx={{ color: colorText800 }}>
                  {e.answer}
                </HtmlText>
              </AccordionDetails>
            </Accordion>
          );
        })}
      <Box my={5} className={"p-align-left"}>
        <Link href={"/faq"} sx={{ color: colorPrimary700 }} variant="h6">
          {t("main-page-faq-button-title")}
        </Link>
      </Box>
    </Box>
  );
};
export default MainFaqBox;
