"use client";
import { FC } from "react";

import { Swiper, SwiperSlide } from "swiper/react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import MainPageCategoryBoxItem from "components/MainPageCategoryBox/MainPageCategoryBoxItem";
import Navigation from "components/MainPageCategoryBox/Navigation";

type MainPageCategoryBoxPropsType = {
  popularCategories: {
    id: number;
    shortName: string;
    icon: string;
    iconColor: string;
    href: string;
    backgroundColor: string;
  }[];
};

const MainPageCategoryBox: FC<MainPageCategoryBoxPropsType> = ({
  popularCategories,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      mt={4}
    >
      <Box style={{ maxWidth: "100%" }}>
        <Swiper slidesPerView={"auto"}>
          {popularCategories.map((popularCategory) => (
            <SwiperSlide key={popularCategory.id} style={{ width: "178px" }}>
              <MainPageCategoryBoxItem
                title={popularCategory.shortName}
                icon={popularCategory.icon}
                href={popularCategory.href}
                backgroundColor={popularCategory.backgroundColor}
                iconColor={popularCategory.iconColor}
              />
            </SwiperSlide>
          ))}
          <Navigation sx={{ display: { sx: "block", xl: "none" } }} />
        </Swiper>
      </Box>
    </Stack>
  );
};
export default MainPageCategoryBox;
