import { FC } from "react";

import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CategoryIcon from "components/CategoryIcons/CategoryIcons";

import { colorText900 } from "theme/colors";

type MainPageCategoryBoxProps = {
  title: string;
  icon: string;
  href: string;
  backgroundColor: string;
  iconColor: string;
};

const MainPageCategoryBoxItem: FC<MainPageCategoryBoxProps> = ({
  href,
  title,
  icon,
  backgroundColor,
  iconColor,
}) => {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={2}
      width={"150px"}
    >
      <Link title={title} href={href}>
        <CategoryIcon
          iconName={icon}
          backgroundColor={backgroundColor}
          iconColor={iconColor}
        />
      </Link>
      <Link
        title={title}
        href={href}
        sx={{ textAlign: "center", color: colorText900, px: "2rem" }}
      >
        <Typography variant={"body1"} component={"h3"} fontWeight={600}>
          {title}
        </Typography>
      </Link>
    </Stack>
  );
};

export default MainPageCategoryBoxItem;
