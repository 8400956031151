import dynamic from "next/dynamic";

const SystemIcons = dynamic(() => import("components/SystemIcons"));

const ProductTypeIconButton = (props: { productType: string }): any => {
  const { productType } = props;

  switch (productType) {
    case "TO_THE_LABORATORY":
      return <SystemIcons iconName={"IcnPobranieKrwiWLab"} />;
    case "COURIER":
      return <SystemIcons iconName={"IcnDostawaKurierem"} />;
    case "HOME":
      return <SystemIcons iconName={"IcnPobranieWDomu"} />;
    case "TO_THE_LABORATORY_WYMAZ":
      return <SystemIcons iconName={"IcnPobranieWymazuWLab"} />;
    case "DELIVERY_TO_LABORATORY":
      return <SystemIcons iconName={"IcnDostarczenieDoLab"} />;
    default:
      return <SystemIcons iconName={"IcnPobranieKrwiWLab"} />;
  }
};

export const getProductTypeText = (
  productType: string,
  t: (key: string) => string,
): string => {
  switch (productType) {
    case "TO_THE_LABORATORY":
      return t("product-productType1");
    case "COURIER":
      return t("product-productType2");
    case "HOME":
      return t("product-productType5");
    case "TO_THE_LABORATORY_WYMAZ":
      return t("product-productType4");
    case "DELIVERY_TO_LABORATORY":
      return t("product-productType3");
    default:
      return "";
  }
};

export default ProductTypeIconButton;
