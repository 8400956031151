"use client";
import { FC } from "react";

import { useTranslations } from "next-intl";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";

import MainFaqBox from "./MainFaqBox";

import { colorPrimary100, colorPrimary700, colorText900 } from "theme/colors";
import { DotIcon } from "theme/SvgIcons";

interface MainFAQTabProps {
  faq: any;
  recommendations?: { id: number; phrase: string; url: string }[];
}

const MainFAQTab: FC<MainFAQTabProps> = ({
  faq,
  recommendations,
}): JSX.Element => {
  const t = useTranslations("common");
  return (
    <Container maxWidth="xl" sx={{ mb: 25 }}>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        sx={{
          borderRadius: 1,
          backgroundColor: colorPrimary100,
          display: { xs: "none", sm: "flex" },
        }}
      >
        <MainFaqBox faq={faq} sx={{ minWidth: { md: "50%" }, padding: 16 }} />
        <Box
          sx={{
            backgroundImage: 'url("/stethoscope.svg")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left",
            minWidth: "50%",
            padding: 16,
            textAlign: "right",
            display: { xs: "none", md: "block" },
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            gutterBottom={true}
            className={"fcx-mb-30 fcx-mt-20"}
            fontWeight={700}
            color={colorText900}
          >
            {t("main-page-recommended-search-title")}
          </Typography>
          {recommendations?.map((recomendation, i) => {
            return (
              <Box sx={{ display: "inline-block" }} key={recomendation.id}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Link
                    variant="subtitle1"
                    href={recomendation.url}
                    sx={{
                      color: colorPrimary700,
                      cursor: "pointer",
                      lineHeight: "3rem!important",
                    }}
                  >
                    {recomendation.phrase}
                  </Link>
                  {i !== recommendations.length - 1 && (
                    <Box component="span">
                      <DotIcon />
                    </Box>
                  )}
                </Stack>
              </Box>
            );
          })}
        </Box>
      </Stack>
    </Container>
  );
};
export default MainFAQTab;
