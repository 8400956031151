import { FC, memo } from "react";

import Box from "@mui/material/Box";

import {
  IcnAlergie,
  IcnBadaniaDNA,
  IcnBadaniaPrenatalne,
  IcnBadanieKrwi,
  IcnBadanieZWymazu,
  IcnMikrofloraJelitowa,
  IcnNietolerancjePokarmowe,
  IcnOpenBook,
  IcnSuplementy,
  IcnBadanieMoczu,
  IcnDostawaKurierem,
  IcnKoronawirus,
} from "theme/icons";
import {
  colorAlergieLightBg,
  colorGenetyczneLightBg,
  colorMikrofloraLightBg,
  colorPrenatalneLightBg,
  colorPrimary100,
  colorSuplementyLightBg,
  colorZKrwiLightBg,
} from "theme/colors";

const CategoryIcon = memo(function SystemIcons({
  iconName,
  iconColor,
  backgroundColor,
}: {
  iconName: string;
  iconColor: string;
  backgroundColor: string;
}) {
  switch (iconName) {
    case "IcnBadaniaDNA":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorGenetyczneLightBg
          }
        >
          <IcnBadaniaDNA fill={iconColor} />
        </IconBox>
      );
    case "IcnNietolerancjePokarmowe":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : colorPrimary100}
        >
          <IcnNietolerancjePokarmowe fill={iconColor} />
        </IconBox>
      );
    case "IcnBadaniaPrenatalne":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorPrenatalneLightBg
          }
        >
          <IcnBadaniaPrenatalne fill={iconColor} />
        </IconBox>
      );
    case "IcnMikrofloraJelitowa":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorMikrofloraLightBg
          }
        >
          <IcnMikrofloraJelitowa fill={iconColor} />
        </IconBox>
      );
    case "IcnBadanieKrwi":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : colorZKrwiLightBg}
        >
          <IcnBadanieKrwi fill={iconColor} />
        </IconBox>
      );
    case "IcnAlergie":
      return (
        <IconBox
          borderColor={backgroundColor ? backgroundColor : colorAlergieLightBg}
        >
          <IcnAlergie fill={iconColor} />
        </IconBox>
      );
    case "IcnSuplementy":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorSuplementyLightBg
          }
        >
          <IcnSuplementy fill={iconColor} />
        </IconBox>
      );
    case "IcnBadanieMoczu":
      return (
        <IconBox borderColor={backgroundColor ? backgroundColor : "#f3ebe4"}>
          <IcnBadanieMoczu fill={iconColor} />
        </IconBox>
      );
    case "IcnBadanieZWymazu":
      return (
        <IconBox
          borderColor={
            backgroundColor ? backgroundColor : colorMikrofloraLightBg
          }
        >
          <IcnBadanieZWymazu fill={iconColor} />
        </IconBox>
      );
    case "IcnDostawaKurierem":
      return (
        <IconBox borderColor={backgroundColor ? backgroundColor : "#F3F1FF"}>
          <IcnDostawaKurierem fill={iconColor} />
        </IconBox>
      );
    case "IcnKoronawirus":
      return (
        <IconBox borderColor={backgroundColor ? backgroundColor : "#fdeeee"}>
          <IcnKoronawirus fill={iconColor} />
        </IconBox>
      );
    default:
      return (
        <IconBox borderColor={backgroundColor ? backgroundColor : "#fdeeee"}>
          <IcnOpenBook fill={iconColor} />
        </IconBox>
      );
  }
});

type IconBoxProps = {
  children: any;
  borderColor: string;
};

const IconBox: FC<IconBoxProps> = ({ children, borderColor }) => {
  return (
    <Box
      component="span"
      sx={{
        "& > svg": { fontSize: "4rem" },
        background: borderColor,
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
        alignItems: "center",
        alignContent: "center",
        overflow: "hidden",
        width: "150px",
        height: "150px",
      }}
    >
      {children}
    </Box>
  );
};

export default CategoryIcon;
