"use client";
import ReactLazyLoad from "react-lazyload";

export interface LazyLoadProps {
  children: React.ReactNode;
}

const LazyLoad: React.FC<LazyLoadProps> = ({ children }) => {
  return <ReactLazyLoad>{children}</ReactLazyLoad>;
};

export default LazyLoad;
