import { sendGTMEvent } from "@next/third-parties/google";

import { API_ROUTES } from "config/routes";

import backend from "utils/backend";

export function addToCard(
  productId: number,
  siteId?: string,
  language?: string,
  productPrice?: string,
  productCategory?: string,
  productName?: string,
  ouidFromUrl: null | string = null,
): void {
  const data = {
    productId: productId,
    quantity: 1,
    siteId: siteId,
    language: language,
    ouid: ouidFromUrl,
  };
  backend
    .post(API_ROUTES.CARD_ADD, data, { withCredentials: true })
    .then((response) => {
      const data = response.data;
      const cartId = data.cartId;
      const ouid = data.ouid;

      if (typeof window !== "undefined") {
        sendGTMEvent({
          event: "addToCart",
          cart_id: cartId,
          ecommerce: {
            currencyCode: "PLN",
            add: {
              products: [
                {
                  name: productName,
                  id: productId,
                  price: productPrice ? parseFloat(productPrice) : productPrice,
                  category: productCategory,
                  quantity: 1,
                },
              ],
            },
          },
        });
      }
      document.location.href = "/cart?ouid=" + ouid;
    });
}
