import { FC } from "react";

import { useTranslations } from "next-intl";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

import { colorText600, colorText800 } from "theme/colors";

interface ProductPricesProps {
  product: any;
  currency: any;
  isConsultationsPage?: boolean;
}

const ProductPrices: FC<ProductPricesProps> = ({
  product,
  currency,
  isConsultationsPage,
}) => {
  const t = useTranslations("common");
  return (
    <>
      {/* START PRICE */}
      {/* START - NO PROMOTION */}
      {!product.promotion && (
        <Grid item xs={12} sx={{ mb: 4 }}>
          <Divider sx={{ my: 4 }} />
          <Stack
            direction="row"
            justifyContent={!isConsultationsPage ? "space-between" : "center"}
            alignItems="center"
            spacing={2}
          >
            <Box>
              {product.salePrice && (
                <>
                  <Typography
                    variant="body2"
                    className={"f-normal"}
                    sx={{ color: colorText600 }}
                  >
                    {t("product-price-sale")}
                  </Typography>
                  <Typography
                    variant="h4"
                    component={"div"}
                    className={"fcx-mr-5"}
                    style={{ textDecoration: "line-through" }}
                    sx={{ color: colorText600 }}
                  >
                    {product.salePrice}&nbsp;{currency}
                  </Typography>
                </>
              )}
            </Box>
            <Box
              className={
                !isConsultationsPage ? "p-align-right" : "p-align-center"
              }
            >
              <Typography
                variant="body2"
                className={"f-normal fcx-mr-5"}
                sx={{ color: colorText800 }}
              >
                {!isConsultationsPage
                  ? t("product-price-retail")
                  : t("product-price-package")}
              </Typography>
              <Typography
                variant="h4"
                component={"div"}
                sx={{ color: colorText800 }}
              >
                {product.retailPrice}&nbsp;{currency}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      )}
      {/* END - NO PROMOTION */}
      {/* START - PROMOTION */}
      {product.promotion && (
        <Grid item xs={12} sx={{ mb: 4 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Box>
              {product.promotion.promotionBeforePrice && (
                <>
                  <Typography
                    variant="body2"
                    className={"f-normal"}
                    sx={{ color: colorText600 }}
                  >
                    {t("omnibus-part-1")}
                  </Typography>
                  <Typography
                    variant="h4"
                    component={"span"}
                    className={"fcx-mr-5"}
                    style={{ textDecoration: "line-through" }}
                    sx={{ color: colorText600 }}
                  >
                    {product.promotion.promotionBeforePrice}&nbsp;{currency}
                  </Typography>
                </>
              )}
            </Box>
            <Box className={"p-align-right"}>
              <Typography
                variant="body2"
                className={"f-normal fcx-mr-5"}
                sx={{ color: colorText800 }}
              >
                {t("product-price-retail")}
              </Typography>
              <Typography
                variant="h4"
                component={"span"}
                sx={{ color: colorText800 }}
              >
                {product.promotion.promotionPrice}&nbsp;{currency}
              </Typography>
            </Box>
          </Stack>
        </Grid>
      )}
      {/* END - PROMOTION */}
      {/* END PRICE */}
    </>
  );
};

export default ProductPrices;
