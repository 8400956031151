"use client";
import { FC, memo, useCallback, useEffect, useState } from "react";

import { useTranslations } from "next-intl";

import AlertTitle from "@mui/material/AlertTitle";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import { API_ROUTES } from "config/routes";

import HtmlText from "components/HtmlText";

import validateEmail from "utils/validate";
import backend from "utils/backend";

import {
  colorPrimary100,
  colorPrimary200,
  colorText50,
  colorText900,
} from "theme/colors";

const NewsletterBox: FC = () => {
  const t = useTranslations("common");
  const [result, setResult] = useState<null | string>(null);
  const [isDataSending, setIsDataSending] = useState(false);

  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [mail, setMail] = useState("");

  const checkErrors = useCallback((): boolean => {
    if (mail.trim() === "") {
      setError(t("form-validation-required"));
      return true;
    } else if (!validateEmail(mail)) {
      setError(t("form-validation-bad-format"));
      return true;
    } else {
      setError(null);
    }
    return false;
  }, [mail, t]);

  const onSubmit = useCallback(() => {
    setIsDataSending(true);
    const d = { email: mail };

    backend
      .post(API_ROUTES.NEWSLETTER_ADD, d)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setResult(data);
        } else {
          setResult("ERROR");
          console.error(response);
        }
      })
      .catch((reason) => {
        setResult("ERROR");
        console.error(reason);
      })
      .finally(() => setIsDataSending(false));
  }, [mail]);

  const handleSubmit = useCallback(() => {
    setTouched(true);

    const isErrors = checkErrors();

    if (!isErrors) {
      onSubmit();
    }
  }, [onSubmit, checkErrors]);

  const handleKeyDown = useCallback(
    (key: string) => {
      if (key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit],
  );

  useEffect(() => {
    if (!touched) {
      return;
    }
    checkErrors();
  }, [t, touched, checkErrors]);

  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      padding={20}
      sx={{
        background: colorPrimary100,
        borderBottom: "1px solid " + colorPrimary200,
        display: { xs: "none", sm: "block" },
      }}
    >
      <HtmlText
        align="center"
        color={colorText900}
        variant="h5"
        fontWeight={700}
      >
        {t("newsletter-box-title")}
      </HtmlText>
      <Stack direction="column" justifyContent="center" alignItems="center">
        {result === "OK" && (
          <Alert severity="success" icon={<></>}>
            <AlertTitle>
              <AlertTitle>{t("common-newsletter-ok")}</AlertTitle>
            </AlertTitle>
          </Alert>
        )}
        {result === "ERROR" && (
          <Alert severity="error" icon={<></>}>
            <AlertTitle>{t("common-newsletter-error")}</AlertTitle>
          </Alert>
        )}
        {result === "DUPLICATE" && (
          <Alert severity="warning" icon={<></>}>
            <AlertTitle>{t("common-newsletter-duplicate")}</AlertTitle>
          </Alert>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <TextField
          value={mail}
          type="text"
          onChange={(event) => setMail(event.target.value)}
          onKeyDown={(event) => handleKeyDown(event.key)}
          helperText={touched && error}
          error={!!(touched && error && error !== "")}
          placeholder={t("newsletter-box-button-placeholder")}
          disabled={isDataSending || (result !== null && result === "OK")}
          sx={{
            "& .MuiInputBase-root": {
              backgroundColor: colorText50,
              minWidth: "300px",
              minHeight: "42px",
            },
          }}
          size="small"
        />
        <Button
          disabled={isDataSending || (result !== null && result === "OK")}
          disableElevation={true}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          style={{
            minHeight: "42px",
          }}
        >
          {t("newsletter-box-button-title")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default memo(NewsletterBox);
