import { FC, ReactNode } from "react";

import { A11y, Navigation } from "swiper/modules";
import { Swiper } from "swiper/react";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material";

import MyNavigation from "components/MainPageCategoryBox/Navigation";

type MainArticleSwiperPropsType = {
  children?: ReactNode;
};

const MainArticleSwiper: FC<MainArticleSwiperPropsType> = ({ children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Swiper
      modules={[Navigation, A11y]}
      breakpoints={{
        1: {
          slidesPerView: 1,
        },
        760: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
      }}
      cssMode
      navigation={isDesktop}
      spaceBetween={10}
      resizeObserver={true}
      grabCursor={true}
      effect="fade"
      style={{ zIndex: 0, position: "relative" }}
    >
      {children}
      <MyNavigation sx={{ display: { sx: "block", sm: "none" } }} />
    </Swiper>
  );
};

export default MainArticleSwiper;
