import { FC, memo } from "react";

import { useSwiper } from "swiper/react";

import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { colorText50 } from "theme/colors";

type SlideNavigationPropsType = {
  itemNumber: number;
  elementsCount: number;
};

const SlideNavigation: FC<SlideNavigationPropsType> = ({
  itemNumber,
  elementsCount,
}) => {
  const swiper = useSwiper();

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <IconButton
        aria-label="Poprzedni slajd"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          swiper.slidePrev();
        }}
      >
        <ArrowCircleLeftOutlinedIcon
          sx={{ fontSize: "3rem", color: colorText50 }}
        />
      </IconButton>
      <Typography
        component="div"
        variant="body2"
        sx={{ fontSize: "1.5rem", color: colorText50, padding: "0!important" }}
      >{`${itemNumber} / ${elementsCount}`}</Typography>
      <IconButton
        aria-label="Następny slajd"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          swiper.slideNext();
        }}
      >
        <ArrowCircleRightOutlinedIcon
          fontSize={"large"}
          sx={{ fontSize: "3rem", color: colorText50 }}
        />
      </IconButton>
    </Stack>
  );
};
export default memo(SlideNavigation);
