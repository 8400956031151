"use client";

import React, { FC } from "react";

import Image from "next/image";

import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import Box from "@mui/material/Box";

import LazyLoad from "components/LazyLoad/LazyLoad";

interface MainBottomCarouselProps {
  sliderBottom: {
    interval: number;
    slides: {
      header: string;
      image: string;
      button: string;
      buttonUrl: string;
    }[];
  };
}
const MainBottomCarousel: FC<MainBottomCarouselProps> = ({ sliderBottom }) => {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: sliderBottom.interval,
        disableOnInteraction: false,
      }}
      loop
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        1: {
          slidesPerView: 3,
        },
        760: {
          slidesPerView: 6,
        },
      }}
      slidesPerView={6}
      resizeObserver={true}
      grabCursor={true}
    >
      {sliderBottom?.slides !== null &&
        sliderBottom?.slides.map((e, i) => {
          return (
            <SwiperSlide key={`slider-${i}`}>
              <Box sx={{ height: 70, marginBottom: 24 }}>
                <LazyLoad>
                  <Image fill src={"/partners" + e.image} alt="Partner Logo" />
                </LazyLoad>
              </Box>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};
export default MainBottomCarousel;
