import { FC } from "react";

import Typography, { TypographyProps } from "@mui/material/Typography";

type HtmlTextProps = TypographyProps & {
  children?: any;
};

const HtmlText: FC<HtmlTextProps> = ({ children, ...rest }): JSX.Element => {
  return (
    <Typography
      {...rest}
      dangerouslySetInnerHTML={{ __html: children || "" }}
    />
  );
};
export default HtmlText;
