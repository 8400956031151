import { FC } from "react";

import Rating from "@mui/material/Rating";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

import { colorOrange } from "theme/colors";

type ShortRatingsProps = {
  rate: string;
};

const ShortRatings: FC<ShortRatingsProps> = ({ rate }) => {
  const rateFloat = parseFloat(rate);
  return rateFloat !== 0 ? (
    <>
      <Rating
        value={rateFloat}
        readOnly
        precision={0.1}
        icon={<StarRoundedIcon />}
        emptyIcon={<StarRoundedIcon />}
        sx={{ color: colorOrange }}
      />
    </>
  ) : (
    <></>
  );
};

export default ShortRatings;
